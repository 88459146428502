<div class="modal-header">
  <h5 class="modal-title">{{ config.title }}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()" [disabled]="isProcessing"></button>
</div>
<div class="modal-body">
  <p class="question">{{ config.question }}</p>
  <p class="explanation" *ngIf="config.explanation">{{ config.explanation }}</p>
</div>
<div class="modal-footer buttons">
  <button type="button" class="btn btn-light btn-sm" (click)="cancel()" [disabled]="isProcessing" ngbAutofocus>
    {{ config.cancel }}
  </button>
  <button
    type="button"
    class="btn btn-sm"
    [ngClass]="{ 'btn-primary': !config.isConfirmationNegative, 'btn-danger': config.isConfirmationNegative }"
    (click)="confirm()"
    [scBtnProcessing]="isProcessing"
  >
    {{ config.confirm }}
  </button>
</div>
