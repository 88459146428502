import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'otaVersionFromFile' })
export class OtaVersionFromFilePipe implements PipeTransform {
  static readonly versionRegex = /scent\.connect\.2_(\d+\.\d+\.\d+)\.bin/;

  static get(fileName: string): string {
    const matches = this.versionRegex.exec(fileName);
    if (!matches || matches.length !== 2) {
      return null;
    }
    return matches[1];
  }

  transform(fileName: string): string {
    return OtaVersionFromFilePipe.get(fileName);
  }
}
