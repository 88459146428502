/* src/app/shared/search/search.component.scss */
:host {
  display: inline-block;
  position: relative;
}
.search-wrapper {
  top: -0.25rem;
}
input:focus {
  box-shadow: unset;
}
.error-result {
  display: inline-block;
  position: absolute;
  top: 2.25rem;
  left: 0;
  z-index: 3;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  width: 13.25rem;
  color: #6c757d;
  -webkit-box-shadow: 0rem 0.125rem 0.3125rem -0.25rem rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0rem 0.125rem 0.3125rem -0.25rem rgba(0, 0, 0, 0.75);
  box-shadow: 0rem 0.125rem 0.3125rem -0.25rem rgba(0, 0, 0, 0.75);
  font-size: 0.875rem;
}
.error-result .error-result-inner {
  border: 1px solid #e84e25;
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem;
}
.error-result p {
  color: #e84e25;
  margin-bottom: 0;
}
/*# sourceMappingURL=search.component.css.map */
