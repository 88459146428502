import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

/* eslint-disable @typescript-eslint/member-ordering */
import { Path } from 'app/data/utils/path.util';
import firebase from 'firebase/compat/app';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

import { DaoFactory, User, UserDAO } from '@data';
import { EnvironmentConfig } from '@env/environment-config';

import { Role } from '../../data/enums';
import { LogService, LogZone } from '../logging';

export interface Claims {
  portal: string;
  role: string;
  locId?: string;
}

const CLAIMS_KEY = 'claims';

@Injectable()
export class AuthService {
  private readonly _user: Observable<User>;
  public get user(): Observable<User> {
    return this._user;
  }

  constructor(
    private environment: EnvironmentConfig,
    private firebaseAuth: AngularFireAuth,
    private daoFactory: DaoFactory,
    private logService: LogService,
  ) {
    this.logService = this.logService.for(LogZone.AUTHENTICATION);
    this._user = this.initUser();
  }

  checkUserDeauthed(callback: (user: firebase.User) => any) {
    return this.firebaseAuth.onAuthStateChanged(callback);
  }

  async signup(email: string, password: string): Promise<firebase.auth.UserCredential> {
    const sessionName = `Session ${Math.ceil(Math.random() * 1000000)}`;
    const userSignUpSession = firebase.initializeApp(this.environment.firebase, sessionName);
    const credentials = await userSignUpSession.auth().createUserWithEmailAndPassword(email, password);
    userSignUpSession.auth().signOut();
    return credentials;
  }

  async login(email: string, password: string): Promise<firebase.auth.UserCredential> {
    const user = await this.firebaseAuth.signInWithEmailAndPassword(email, password);
    return user;
  }

  async logout() {
    this.setClaims(null);
    this.firebaseAuth.signOut();
  }

  async sendPasswordResetEmail(email: string) {
    const actionCodeSettings = {
      url: `${window.location.origin}`,
    };
    return this.firebaseAuth.sendPasswordResetEmail(email, actionCodeSettings);
  }

  async verifyPasswordResetCode(code: string) {
    return this.firebaseAuth.verifyPasswordResetCode(code);
  }

  async confirmPasswordReset(code: string, newPassword: string) {
    return this.firebaseAuth.confirmPasswordReset(code, newPassword);
  }

  getCurrentUser() {
    return firebase.auth().currentUser;
  }

  private initUser(): Observable<User> {
    return this.firebaseAuth.idToken.pipe(
      switchMap((idToken) => {
        if (idToken == null) return of(null);
        const path = Path.user(this.getCurrentUser().uid);
        return this.daoFactory.build(UserDAO, path).pipe(
          switchMap((x) => (x ? x.stream : of(null))),
          shareReplay(1),
        );
      }),
    );
  }

  /**
   * Get the decoded ID JWT or null if it doesn't exist.
   */
  async getIdToken(): Promise<any> {
    try {
      const idToken = await (await this.firebaseAuth.currentUser).getIdTokenResult();
      const { claims } = idToken.claims as { claims: Claims };
      this.setClaims(claims);
      return { claims };
    } catch (error) {
      this.logService.error('Unable to retrieve user ID token ', { error });
      return null;
    }
  }

  private setClaims(claims: Claims) {
    localStorage.setItem(CLAIMS_KEY, JSON.stringify(claims));
  }

  /**
   * Get the user's claims or null if they don't exist.
   */
  getClaims(): Claims {
    try {
      return JSON.parse(localStorage.getItem(CLAIMS_KEY));
    } catch (error) {
      this.logService.error('An error occurred while loading custom claims from Local Storage', { error });
      return null;
    }
  }

  getRole(): Role {
    return this.getClaims() ? (this.getClaims().role as Role) : Role.Kiosk;
  }
}
