<div class="agg-counts">
  <div
    class="agg-count"
    *ngFor="let count of _counts"
    [ngClass]="{ filter: filterable && count.count, active: filterable && count.selected }"
    (click)="updateFilter(count)"
  >
    <p
      class="agg-number"
      [ngClass]="{
        'active-number': count.count && count.type !== 'alert',
        'active-alert': count.count && count.type === 'alert'
      }"
    >
      {{ count.count }}
    </p>
    <p class="agg-description">{{ count.name }}</p>
  </div>
</div>
