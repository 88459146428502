import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationConfig } from './confirmation-config.model';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

/**
 * Used to solicit user feedback to confirm an action.
 *
 * @export
 * @class ConfirmationService
 */
@Injectable()
export class ConfirmationService {
  private modal: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private router: Router,
  ) {
    // automatically close the modal on route change
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.close(false);
    });
  }

  /**
   *
   *
   * Get confirmation from the user that they want to perform a particular action.
   *
   *
   *
   * @param config
   * @returns Did the user confirm?
   * @memberof ConfirmationService
   */
  public confirm(config: ConfirmationConfig): Promise<boolean> {
    this.close(false);
    // so that we can disable closing the modal during processing
    const modalOptions = {
      backdrop: 'static',
      keyboard: false,
    } as NgbModalOptions;
    this.modal = this.modalService.open(ConfirmationDialogComponent, modalOptions);
    const component = this.modal.componentInstance as ConfirmationDialogComponent;
    component.config = config;
    const promise = new Promise<boolean>((resolve) => {
      this.modal.result
        .then((isConfirmed: boolean) => {
          resolve(isConfirmed);
        })
        .catch(() => {
          resolve(false);
        })
        .then(() => {
          this.modal = null;
        });
    });
    return promise;
  }

  public close(isConfirmed: boolean): void {
    if (this.modal) {
      this.modal.close(isConfirmed);
      this.modal = null;
    }
  }
}
