import { Injectable } from '@angular/core';

import moment from 'moment';

@Injectable()
export class UtilService {
  defaultLength = 40;

  shorten(str: string, length?: number) {
    const useLen = length || this.defaultLength;
    return str.length > useLen ? `${str.substring(0, useLen - 1)}…` : str;
  }

  getPastDays(numDays: number, timezone: string): string[] {
    // get an array from 0 to n - 1
    const days = Array.from(Array(numDays).keys());
    return days.map((day) => moment.tz(timezone).subtract(day, 'days').format('YYYY-MM-DD'));
  }
}
