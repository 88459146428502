/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { firstValueFrom } from 'rxjs';

import { LogMessage } from '../log-message.model';
import { LogAppender } from './log-appender.service';

@Injectable()
export class CloudAppender implements LogAppender {
  constructor(private aff: AngularFireFunctions) {}

  async write(logMessage: LogMessage): Promise<void> {
    await firstValueFrom(this.aff.httpsCallable('log')(logMessage));
  }
}
