import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationConfig } from './notification-config.model';

@Component({
  selector: 'sc-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
})
export class NotificationDialogComponent {
  @Input()
  public config: NotificationConfig;

  public isProcessing: boolean;

  constructor(private modal: NgbActiveModal) {}

  /**
   *
   *
   * Handle the user dismissing the dialog by clicking the 'x'
   *
   * in the upper right corer.
   *
   *
   *
   * @returns
   * @memberof NotificationDialogComponent
   */
  public dismiss(): void {
    this.modal.dismiss();
  }
}
