import { Subscription } from 'rxjs';

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { PaginatorService } from './paginator.service';

export interface PageState {
  startRange: number;
  endRange: number;
}

@Component({
  selector: 'sc-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() numItems = 0;
  @Output() pageState: EventEmitter<PageState> = new EventEmitter<PageState>();

  currentPage = 1;
  numPages = 1;
  currentPageInner = this.currentPage;
  itemsPerPage = 10; // setting

  startRange = 0;
  endRange = 0;

  private readonly sub = new Subscription();

  constructor(private paginatorService: PaginatorService) {
    this.sub.add(
      paginatorService.$currentPage.subscribe((currentPage) => {
        this.currentPage = currentPage;
        this.currentPageInner = currentPage;
        this.configureRange();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.numItems) {
      this.numItems = changes.numItems.currentValue;
      this.numPages = Math.ceil(this.numItems / this.itemsPerPage) || 1;

      if (this.currentPage > this.numPages) {
        this.paginatorService.setPage(this.numPages);
      }
    }

    this.configureRange();
  }

  configureRange() {
    const oldStartRange = this.startRange;
    const oldEndRange = this.endRange;
    this.startRange = (this.currentPage - 1) * this.itemsPerPage + 1;
    this.endRange = Math.min(this.currentPage * this.itemsPerPage, this.numItems);
    if (oldStartRange !== this.startRange || oldEndRange !== this.endRange) {
      // timeout so that an input change doesn't immediately trigger an output change
      setTimeout(() => this.pageState.emit({ startRange: this.startRange, endRange: this.endRange }), 0);
    }
  }

  updatePage() {
    const newPage = Math.trunc(this.currentPageInner);
    if (Number.isNaN(newPage) || newPage < 1 || newPage > this.numPages) {
      this.currentPageInner = this.currentPage;
      return;
    }

    this.paginatorService.setPage(newPage);
  }

  navToFirstPage() {
    this.paginatorService.setPage(1);
  }

  navToPrevPage() {
    this.paginatorService.setPage(Math.max(this.currentPage - 1, 1));
  }

  navToNextPage() {
    this.paginatorService.setPage(Math.min(this.currentPage + 1, this.numPages));
  }

  navToLastPage() {
    this.paginatorService.setPage(this.numPages);
  }

  ngOnInit() {
    this.configureRange();
  }

  ngOnDestroy(): void {
    this.paginatorService.setPage(1);
    this.sub.unsubscribe();
  }
}
