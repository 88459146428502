import { Pipe, PipeTransform } from '@angular/core';

const locale = new Map<string, string>([['en-us', 'English - United States']]);

@Pipe({ name: 'locale' })
export class LocalePipe implements PipeTransform {
  transform(code: string): string {
    return locale.has(code) ? locale.get(code) : code;
  }
}
