<div class="item-display">
  <p *ngIf="numPages > 1">
    <span>
      <strong>{{ startRange }} - {{ endRange }}</strong>
    </span>
    &nbsp;<span>of {{ numItems }} items</span>
  </p>
  <p *ngIf="numPages === 1">
    <strong>{{ numItems }}</strong> {{ numItems === 1 ? 'item' : 'items' }}
  </p>
</div>
<div class="page-control" *ngIf="numPages > 1">
  <i class="fas fa-chevron-double-left" (click)="navToFirstPage()" [ngClass]="{ disabled: currentPage === 1 }"></i>
  <i class="fas fa-chevron-left" (click)="navToPrevPage()" [ngClass]="{ disabled: currentPage === 1 }"></i>
  <p class="page-page">Page</p>
  <p class="page-number">{{ currentPageInner }}</p>
  <input
    type="number"
    class="form-control"
    [(ngModel)]="currentPageInner"
    (blur)="updatePage()"
    (keyup.enter)="updatePage()"
  />
  <i class="fas fa-chevron-right" (click)="navToNextPage()" [ngClass]="{ disabled: currentPage === numPages }"></i>
  <i
    class="fas fa-chevron-double-right"
    (click)="navToLastPage()"
    [ngClass]="{ disabled: currentPage === numPages }"
  ></i>
</div>
