/* src/app/shared/breadcrumb/breadcrumb.component.scss */
.bread-crumb {
  padding: 0 0.25rem 0.125rem;
}
.bread-crumb p {
  display: inline-block;
  margin: 0;
  cursor: default;
}
.bread-crumb .spacer {
  color: #adb5bd;
  vertical-align: middle;
}
.bread-crumb .level {
  display: inline-block;
  padding: 0 0.625rem;
  color: #456c9b;
  cursor: pointer;
  max-width: 280px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.bread-crumb .level:hover:not(.current) {
  color: #355378;
  text-decoration: none;
}
.bread-crumb .level.current {
  color: #adb5bd;
  cursor: default;
}
@media (max-width: 750px) {
  .bread-crumb span,
  .bread-crumb a {
    display: block;
  }
  .bread-crumb .spacer,
  .bread-crumb .current {
    display: none;
  }
}
/*# sourceMappingURL=breadcrumb.component.css.map */
