import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { Role } from '@data';

import { AuthService, DashboardService } from '../services';

// eslint-disable-next-line func-style
export const locIdGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const claims = inject(AuthService).getClaims();

  if (claims.role === Role.Manager || claims.locId === route.params.locId) {
    return true;
  } else {
    inject(DashboardService).goToDashboard();
    inject(ToastrService).error('You are not authorized to access this page.');
    return false;
  }
};
