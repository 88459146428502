/* src/app/shared/week-picker/week-picker.component.scss */
.day {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75rem;
  border-radius: 1.75rem;
  text-align: center;
  vertical-align: middle;
  margin: 0 0.25rem 0 0;
  padding-left: 0.0625rem;
  background-color: #f8f9fa;
  cursor: pointer;
}
.day.active {
  background-color: #456c9b;
  color: #fff;
}
/*# sourceMappingURL=week-picker.component.css.map */
