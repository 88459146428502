import moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'datetime' })
export class DatetimePipe implements PipeTransform {
  transform(value: string | number, timezone?: string, displaySeconds = true): string {
    return moment(value)
      .tz(timezone || moment.tz.guess())
      .format(displaySeconds ? 'MMM DD, YYYY HH:mm:ss z' : 'MMM DD, YYYY HH:mm z');
  }
}
