import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDateParserFormatter, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import { AggregateComponent } from './aggregate';
import { BreadcrumbComponent } from './breadcrumb';
import { AutoFocusOnLoadDirective, BtnProcessingDirective } from './directives';
import { FooterComponent } from './footer';
import { DatepickerConfig, MomentDateFormatter } from './ng-bootstrap';
import { PaginatorComponent } from './paginator';
import {
  AssetStatePipe,
  DatetimePipe,
  LocalePipe,
  OtaVersionFromFilePipe,
  RoleNamePipe,
  TempNamePipe,
  TempUnitPipe,
  TimezonePipe,
} from './pipes';
import { SearchComponent } from './search';
import { WeekPickerComponent } from './week-picker';

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule],
  declarations: [
    AggregateComponent,
    AggregateComponent,
    AssetStatePipe,
    BreadcrumbComponent,
    AutoFocusOnLoadDirective,
    BtnProcessingDirective,
    DatetimePipe,
    FooterComponent,
    LocalePipe,
    PaginatorComponent,
    RoleNamePipe,
    SearchComponent,
    TempNamePipe,
    TempUnitPipe,
    TimezonePipe,
    OtaVersionFromFilePipe,
    WeekPickerComponent,
  ],
  providers: [
    LocalePipe,
    RoleNamePipe,
    TempNamePipe,
    TempUnitPipe,
    TimezonePipe,
    { provide: NgbDatepickerConfig, useClass: DatepickerConfig },
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
  ],
  exports: [
    AggregateComponent,
    BreadcrumbComponent,
    CommonModule,
    FooterComponent,
    FormsModule,
    PaginatorComponent,
    AutoFocusOnLoadDirective,
    BtnProcessingDirective,
    AssetStatePipe,
    DatetimePipe,
    LocalePipe,
    RoleNamePipe,
    SearchComponent,
    TempNamePipe,
    TempUnitPipe,
    TimezonePipe,
    OtaVersionFromFilePipe,
    WeekPickerComponent,
  ],
})
export class SharedModule {}
