import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationConfig } from './confirmation-config.model';

@Component({
  selector: 'sc-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  @Input()
  public config: ConfirmationConfig;

  public isProcessing: boolean;

  constructor(private modal: NgbActiveModal) {}

  /**
   *
   *
   * Handle the user dismissing the dialog by clicking the 'x'
   *
   * in the upper right corer.
   *
   * Don't allow this action if the modal is in the 'processing' state.
   *
   *
   *
   * @returns
   * @memberof ConfirmationDialogComponent
   */
  public dismiss(): void {
    if (this.isProcessing) {
      return;
    }
    this.modal.dismiss();
  }

  /**
   *
   *
   * Handle the user choosing the cancel action.
   *
   * Don't allow this action if the modal is in the 'processing' state.
   *
   *
   *
   * @returns
   * @memberof ConfirmationDialogComponent
   */
  public cancel(): void {
    if (this.isProcessing) {
      return;
    }
    this.modal.close(false);
  }

  /**
   *
   *
   * Handle the user choosing the confirmation action.
   *
   * Don't allow this action if the modal is in the 'processing' state.
   *
   * If an onConfirm callback is provided then put the modal in a processing
   *
   * state till it completes. If successful then close, else leave open.
   *
   *
   *
   * @returns
   * @memberof ConfirmationDialogComponent
   */
  public confirm(): void {
    if (this.isProcessing) {
      return;
    }
    this.isProcessing = true;

    if (this.config.onConfirm) {
      this.config
        .onConfirm()
        .then(() => {
          this.modal.close(true);
        })
        .catch(() => {
          this.isProcessing = false;
        });
    } else {
      this.modal.close(true);
    }
  }
}
