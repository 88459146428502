import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { firstValueFrom } from 'rxjs';

@Injectable()
export class LocationService {
  constructor(private firebaseFunctions: AngularFireFunctions) {}

  async archiveLocation(params: { locId: string }) {
    const archive = this.firebaseFunctions.httpsCallable('archiveLocation');
    return firstValueFrom(archive(params));
  }
}
