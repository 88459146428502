export const LOG_ZONE = 'LOG_ZONE';

export const LogZone = {
  GLOBAL: 'Global',
  ASSET: 'Asset',
  AUTHENTICATION: 'Authentication',
  FIRESTORE: 'Firestore',
  LOCATION: 'Location',
  LOCATIONS: 'Locations',
  USER: 'User',
  USERS: 'Users',
  OTA: 'OTA Console',
};
