import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { firstValueFrom } from 'rxjs';

export interface EventLogResult {
  table: string;
  timestamp: number;
  properties: [string, string][]; // Can be converted to a map using basic constructor
  events?: [string, string][][]; // each item in outer array can be converted to a map
}

@Injectable()
export class EventLogService {
  constructor(private firebaseFunctions: AngularFireFunctions) {}

  async queryEventLog(mac: string, hoursAgo: number): Promise<EventLogResult[]> {
    const callable = this.firebaseFunctions.httpsCallable<any, EventLogResult[]>('eventLog');
    return firstValueFrom(callable({ mac, hoursAgo }));
  }
}
