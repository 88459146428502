<div class="modal-header">
  <h5 class="modal-title">{{ config.title }}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
  <p>{{ config.statement }}</p>
</div>
<div class="modal-footer buttons">
  <button type="button" class="btn btn-sm btn-primary" (click)="dismiss()" ngbAutofocus>OK</button>
</div>
