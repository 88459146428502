import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Route } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { AuthService, DashboardService } from '../services';

// eslint-disable-next-line func-style
export const roleGuard: CanActivateFn | CanMatchFn = (route: Route | ActivatedRouteSnapshot) => {
  const claims = inject(AuthService).getClaims();
  const roles = (route.data?.roles as Array<string>) || [];

  if (roles.includes(claims.role)) {
    return true;
  } else {
    inject(DashboardService).goToDashboard();
    inject(ToastrService).error('You are not authorized to access this page.');
    return false;
  }
};
