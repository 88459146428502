import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivateFn } from '@angular/router';

import { of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { DashboardService } from '../services';

// eslint-disable-next-line func-style
export const loginGuard: CanActivateFn = () => {
  const dashboardService = inject(DashboardService);
  return inject(AngularFireAuth).user.pipe(
    first(),
    switchMap((user) => {
      if (!user) {
        return of(true);
      } else {
        dashboardService.goToDashboard();
        return of(false);
      }
    }),
  );
};
