export class Path {
  public static users(): string {
    return `mfgUsers`;
  }

  public static user(userId: string): string {
    return `${this.users()}/${userId}`;
  }

  public static assetTypes(): string {
    return `assetTypes`;
  }

  public static assetType(assetTypeId: string): string {
    return `${this.assetTypes()}/${assetTypeId}`;
  }

  public static locations(): string {
    return `mfgLocations`;
  }

  public static location(locationId: string): string {
    return `${this.locations()}/${locationId}`;
  }

  public static unclaimedAssets(): string {
    return `unclaimedAssets`;
  }

  public static unclaimedAsset(assetId): string {
    return `${this.unclaimedAssets()}/${assetId}`;
  }

  public static assets(locationId: string): string {
    return `${this.location(locationId)}/mfgAssets`;
  }

  public static asset(locationId: string, assetId: string): string {
    return `${this.assets(locationId)}/${assetId}`;
  }

  public static enterpriseAsset(organizationId: string, locationId: string): string {
    return `organizations/${organizationId}/locations/${locationId}`;
  }

  public static homeAsset(locationId: string): string {
    return `homeLocations/${locationId}`;
  }

  public static otaJobs() {
    return 'otaJobs';
  }

  public static otaJob(jobId: string) {
    return `${this.otaJobs()}/${jobId}`;
  }

  public static getPath(path: string, segCount: number): string {
    return path.split('/').slice(0, segCount).join('/');
  }

  // from enterprise portal
  public static organizations(): string {
    return 'organizations';
  }

  public static locationsCollectionGroup(): string {
    return 'locations';
  }
}
