import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { firstValueFrom, merge, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AssetDAO, DaoFactory, Path } from '@data';

@Injectable()
export class AssetService {
  constructor(
    private daoFactory: DaoFactory,
    private firebaseFunctions: AngularFireFunctions,
  ) {}

  getAsset(locId: string, assetId: string): Observable<AssetDAO> {
    const mfgAsset = this.daoFactory.build(AssetDAO, Path.asset(locId, assetId));
    const unclaimedAsset = this.daoFactory.build(AssetDAO, Path.unclaimedAsset(assetId));
    return merge(mfgAsset, unclaimedAsset).pipe(filter((x) => x != null));
  }

  async resetAsset(params: { mac: string; locId: string }) {
    const resetAsset = this.firebaseFunctions.httpsCallable('resetAsset');
    return firstValueFrom(resetAsset(params));
  }
}
