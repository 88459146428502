import 'firebase/compat/functions';
import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, Optional, NgModule } from '@angular/core';
import * as i1 from '@angular/fire';
import { VERSION } from '@angular/fire';
import * as i2 from '@angular/fire/app-check';
import { ɵfirebaseAppFactory as _firebaseAppFactory, ɵcacheInstance as _cacheInstance, ɵlazySDKProxy as _lazySDKProxy, FIREBASE_OPTIONS, FIREBASE_APP_NAME, ɵapplyMixins as _applyMixins } from '@angular/fire/compat';
import { of, from } from 'rxjs';
import { observeOn, switchMap, map, shareReplay } from 'rxjs/operators';
import firebase from 'firebase/compat/app';

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
// Export a null object with the same keys as firebase/compat/functions, so Proxy can work with proxy-polyfill in Internet Explorer
const proxyPolyfillCompat = {
    useEmulator: null,
    useFunctionsEmulator: null,
    httpsCallable: null,
};

const ORIGIN = new InjectionToken('angularfire2.functions.origin');
const REGION = new InjectionToken('angularfire2.functions.region');
const USE_EMULATOR = new InjectionToken('angularfire2.functions.use-emulator');
class AngularFireFunctions {
    httpsCallable;
    constructor(options, name, zone, schedulers, region, origin, _useEmulator, // can't use the tuple here
    _appCheckInstances) {
        const useEmulator = _useEmulator;
        const functions = of(undefined).pipe(observeOn(schedulers.outsideAngular), switchMap(() => import('firebase/compat/functions')), map(() => _firebaseAppFactory(options, zone, name)), map(app => _cacheInstance(`${app.name}.functions.${region || origin}`, 'AngularFireFunctions', app.name, () => {
            let functions;
            if (region && origin) {
                throw new Error('REGION and ORIGIN can\'t be used at the same time.');
            }
            functions = app.functions(region || origin || undefined);
            if (useEmulator) {
                functions.useEmulator(...useEmulator);
            }
            return functions;
        }, [region, origin, useEmulator])), shareReplay({ bufferSize: 1, refCount: false }));
        this.httpsCallable = (name, options) => (data) => from(functions).pipe(observeOn(schedulers.insideAngular), switchMap(functions => functions.httpsCallable(name, options)(data)), map(r => r.data));
        return _lazySDKProxy(this, functions, zone);
    }
    static ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.0", ngImport: i0, type: AngularFireFunctions, deps: [{ token: FIREBASE_OPTIONS }, { token: FIREBASE_APP_NAME, optional: true }, { token: i0.NgZone }, { token: i1.ɵAngularFireSchedulers }, { token: REGION, optional: true }, { token: ORIGIN, optional: true }, { token: USE_EMULATOR, optional: true }, { token: i2.AppCheckInstances, optional: true }], target: i0.ɵɵFactoryTarget.Injectable });
    static ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "18.0.0", ngImport: i0, type: AngularFireFunctions, providedIn: 'any' });
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.0", ngImport: i0, type: AngularFireFunctions, decorators: [{
            type: Injectable,
            args: [{
                    providedIn: 'any'
                }]
        }], ctorParameters: () => [{ type: undefined, decorators: [{
                    type: Inject,
                    args: [FIREBASE_OPTIONS]
                }] }, { type: undefined, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [FIREBASE_APP_NAME]
                }] }, { type: i0.NgZone }, { type: i1.ɵAngularFireSchedulers }, { type: undefined, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [REGION]
                }] }, { type: undefined, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [ORIGIN]
                }] }, { type: undefined, decorators: [{
                    type: Optional
                }, {
                    type: Inject,
                    args: [USE_EMULATOR]
                }] }, { type: i2.AppCheckInstances, decorators: [{
                    type: Optional
                }] }] });
_applyMixins(AngularFireFunctions, [proxyPolyfillCompat]);

class AngularFireFunctionsModule {
    constructor() {
        firebase.registerVersion('angularfire', VERSION.full, 'fn-compat');
    }
    static ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "18.0.0", ngImport: i0, type: AngularFireFunctionsModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
    static ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "14.0.0", version: "18.0.0", ngImport: i0, type: AngularFireFunctionsModule });
    static ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "18.0.0", ngImport: i0, type: AngularFireFunctionsModule, providers: [AngularFireFunctions] });
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "18.0.0", ngImport: i0, type: AngularFireFunctionsModule, decorators: [{
            type: NgModule,
            args: [{
                    providers: [AngularFireFunctions]
                }]
        }], ctorParameters: () => [] });

/**
 * Generated bundle index. Do not edit.
 */

export { AngularFireFunctions, AngularFireFunctionsModule, ORIGIN, REGION, USE_EMULATOR };

