import { LogService } from '@core';

/**
 * Assists in peforming safe (all tasks execute) parallel operations.
 * Promise.all will reject as soon as any rejects. So we wrap all tasks
 * so that none reject and then throw at the end if any failed.
 */
export class Parallel {
  public static async forEach<T>(logService: LogService, items: T[], task: (item: T) => Promise<void>): Promise<void> {
    await this.map(logService, items, task);
  }

  public static async map<Ti, To>(logService: LogService, items: Ti[], task: (item: Ti) => Promise<To>): Promise<To[]> {
    const errors = [];
    const tasks = items.map(async (item) => {
      try {
        return await task(item);
      } catch (e) {
        logService.error('Error processing parallel task', e);
        errors.push(e);
      }
      return null;
    });

    const results = await Promise.all(tasks);

    if (errors.length > 0) {
      const successes = results.length - errors.length;
      const failures = errors.length;
      throw new Error(`All tasks did not complete successfully. Successes (${successes}), Failures (${failures}).`);
    }
    return results as To[];
  }
}
