import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { firstValueFrom } from 'rxjs';

import { Hardware } from '@data';

export interface FilterAssetsRequest {
  otaJobId?: string;
  organizationId?: string;
  locationId?: string;
  hardware?: string;
  earliestVersion?: string;
  latestVersion?: string;
  pagination?: FilterAssetsPagination;
  searchLinkLabsHardware: boolean;
  isConnected?: boolean;
}

export interface FilterAssetsPagination {
  size: number;
  page: number;
}

export interface FilterAssetsResponse {
  assets: ElasticsearchAsset[];
  total: number;
}

export interface ElasticsearchAsset {
  name?: string;
  mac: string;
  type: EsAssetType;
  hardware: Hardware;
  version: string;
  locationId: string;
  organizationId: string;
  isOnline: boolean;
}

export enum EsAssetType {
  ENTERPRISE = 'ENTERPRISE',
  HOME = 'HOME',
  MANUFACTURING = 'MANUFACTURING',
  UNCLAIMED = 'UNCLAIMED',
}

type NumberToString = { [key: string]: number };

@Injectable()
export class ElasticsearchService {
  constructor(private firebaseFunctions: AngularFireFunctions) {}

  async getAssetVersionAggregate(): Promise<NumberToString> {
    const callable = this.firebaseFunctions.httpsCallable<void, NumberToString>('assetsPerFirmwareVersion');
    return firstValueFrom(callable());
  }

  async getOtaJobAssetVersionCount(otaJobId: string): Promise<NumberToString> {
    const callable = this.firebaseFunctions.httpsCallable<any, NumberToString>('assetCountForOtaJob');
    return firstValueFrom(callable({ otaJobId }));
  }

  async filterAssets(filter: FilterAssetsRequest): Promise<FilterAssetsResponse> {
    const callable = this.firebaseFunctions.httpsCallable<FilterAssetsRequest, FilterAssetsResponse>(
      'filterElasticsearchAssets',
    );
    return firstValueFrom(callable(filter));
  }
}
