import { Observable } from 'rxjs';

import { Location } from '../models';
import { BaseDAO } from './base.dao';
import { AssetDAO } from './asset.dao';

export class LocationDAO extends BaseDAO<Location> {
  private _assets: Observable<AssetDAO[]>;

  public get mfgAssets(): Observable<AssetDAO[]> {
    if (this._assets == null) {
      const path = `${this.path}/mfgAssets`;
      this._assets = this.initCollection(AssetDAO, path);
    }
    return this._assets;
  }
}
