<footer>
  <p>
    <span class="info-group">3810 Shutterfly Rd. Charlotte, NC 28217</span>
    <span class="separator">|</span>
    <span class="info-group">866.723.6824</span>
  </p>
  <p>
    <span class="info-group">
      <a href="https://www.scentair.com/legal/privacy.html" target="_blank">Privacy Policy</a>
      <span class="separator permanent">|</span>
      <a href="https://www.scentair.com/legal/termsofuse.html" target="_blank">Terms of Service</a>
    </span>
    <span class="separator">|</span>
    <span class="info-group">©2019 ScentAir – All rights reserved</span>
  </p>
</footer>
