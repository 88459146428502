import { AssetState } from '../enums';

export function getState(state: AssetState) {
  switch (state) {
    case AssetState.Provisioned:
      return 'Provisioned';
    case AssetState.BurnIn:
      return 'Burn-in';
    case AssetState.BurnInFail:
      return 'Burn-in Failed';
    case AssetState.Calibration:
      return 'Calibration';
    case AssetState.Complete:
      return 'Complete';
    case AssetState.Hidden:
      return 'Hidden';
    default:
      return '';
  }
}
