import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AfterContentInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

interface PriorityLock {
  priority: number;
  id: symbol;
}

/**
 * Autofocus a field on route change.
 * It has a king-of-the-hill style coordination between the different instances
 * of this directive. The instance with the highest priority will take the focus
 * from those with lower and those with lower cannot take it from those with higher.
 * If you have an equal priority, then the last one wins.
 *
 * @export
 * @class AutoFocusOnLoadDirective
 * @implements {OnChanges}
 */
@Directive({
  selector: '[scAutoFocusOnLoad]',
})
export class AutoFocusOnLoadDirective implements AfterContentInit, OnDestroy {
  private static lock = new BehaviorSubject<PriorityLock>(null);

  @Input()
  public set scAutoFocusOnLoad(value: any) {
    // parse first because isNaN('') is false
    const parsedValue = parseInt(value, 10);
    if (Number.isNaN(parsedValue)) {
      return;
    }
    this.priority = value;
  }

  private priority = 0;

  private id = Symbol('scAutoFocusOnLoad');
  private readonly lockSub: Subscription;
  private readonly routerSub: Subscription;

  constructor(
    private el: ElementRef,
    private router: Router,
  ) {
    this.lockSub = this.watchForLockRelease();
    this.routerSub = this.watchForRouteChange();
  }

  grabFocusLock(): boolean {
    let lock = AutoFocusOnLoadDirective.lock.value;
    if (lock != null) {
      if (this.priority < lock.priority) {
        // someone with a higher priority has the lock
        return false;
      } else if (lock.id === this.id) {
        // we already have the lock
        return false;
      }
    }

    lock = {
      priority: this.priority,
      id: this.id,
    };
    AutoFocusOnLoadDirective.lock.next(lock);
    return true;
  }

  releaseFocusLock(): void {
    const lock = AutoFocusOnLoadDirective.lock.value;
    if (lock == null || lock.id !== this.id) {
      return;
    }
    AutoFocusOnLoadDirective.lock.next(null);
  }

  focus(): void {
    // don't set the focus if another input with a higher priority already has
    if (!this.grabFocusLock()) {
      return;
    }

    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 0);
  }

  watchForLockRelease(): Subscription {
    // try to get the lock if someone else releases it
    return AutoFocusOnLoadDirective.lock.pipe(filter((x) => x == null)).subscribe(() => {
      this.focus();
    });
  }

  watchForRouteChange(): Subscription {
    // generally this only matters with search
    return this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      this.focus();
    });
  }

  ngAfterContentInit(): void {
    // attempt to set the focus on page load
    this.focus();
  }

  ngOnDestroy(): void {
    if (this.lockSub) {
      this.lockSub.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    this.releaseFocusLock();
  }
}
