import moment from 'moment';

import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class MomentDateFormatter extends NgbDateParserFormatter {
  readonly DT_FORMAT = 'MM/DD/YYYY';

  parse(val: string): NgbDateStruct {
    if (val) {
      const value = val.trim();
      const mdt = moment(value, this.DT_FORMAT);
      return {
        day: mdt.day(),
        month: mdt.month(),
        year: mdt.year(),
      } as NgbDateStruct;
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) return '';
    const mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) return '';
    return mdt.format(this.DT_FORMAT);
  }
}
