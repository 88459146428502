import { DayOfWeek, Event } from '@data';

export const BURN_IN_EVENT_ID = 'BURN_IN_EVENT';
export const CALIBRATION_EVENT_ID = 'CALIBRATION_EVENT';
export const DEFAULT_EVENT_IDS = {
  1: 'DEFAULT_EVENT_1',
  2: 'DEFAULT_EVENT_2',
  3: 'DEFAULT_EVENT_3',
};

export const BURN_IN_EVENT = {
  eventId: BURN_IN_EVENT_ID,
  startHour: 0,
  startMinute: 0,
  stopHour: 0,
  stopMinute: 0,
  daysOfWeek: [
    DayOfWeek.Sunday,
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,
    DayOfWeek.Saturday,
  ],
  scentLevel: 95,
  scentTime: 5,
};

export const CALIBRATION_EVENT = {
  eventId: CALIBRATION_EVENT_ID,
  scentLevel: 95,
  scentTime: 1,
  startHour: 0,
  startMinute: 0,
  stopHour: 0,
  stopMinute: 0,
  daysOfWeek: [
    DayOfWeek.Sunday,
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,
    DayOfWeek.Saturday,
  ],
} as Event;
