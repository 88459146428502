import { Pipe, PipeTransform } from '@angular/core';

const temp = new Map<string, string>([
  ['F', 'Fahrenheit'],
  ['C', 'Celsius'],
]);

@Pipe({ name: 'tempName' })
export class TempNamePipe implements PipeTransform {
  transform(code: string): string {
    return temp.has(code) ? temp.get(code) : code;
  }
}
