/* src/app/shared/footer/footer.component.scss */
footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  border-top: 1px solid #e9ecef;
  font-size: 14px;
}
footer p {
  margin: 0.25rem 1rem;
}
footer .separator {
  display: inline-block;
  padding: 0 0.375rem;
}
@media (max-width: 991.98px) {
  footer {
    flex-direction: column;
  }
}
@media (max-width: 575.98px) {
  footer .separator:not(.permanent) {
    display: none;
  }
  .info-group {
    display: block;
  }
}
/*# sourceMappingURL=footer.component.css.map */
