import { Role } from 'app/data/enums/role.enum';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoute } from '@shared';

import { LogService, LogZone } from '../logging';
import { AuthService } from './auth.service';

@Injectable()
export class DashboardService {
  constructor(
    private authService: AuthService,
    private logService: LogService,
    private router: Router,
  ) {
    this.logService = this.logService.for(LogZone.AUTHENTICATION);
  }

  async goToDashboard(returnUrl?: string): Promise<boolean> {
    const token = await this.authService.getIdToken();
    if (token && token.claims) {
      if (token.claims.portal !== 'MANUFACTURING') {
        this.logService.trace('User accessed incorrect portal. Logging out.');
        this.authService.logout();
        return false;
      } else if (token.claims.role === Role.Manager) {
        this.logService.trace('Redirecting user to the locations dashboard.');
        this.router.navigateByUrl(returnUrl || `/location-directory`);
        return true;
      } else if (token.claims.role === Role.Operator) {
        this.logService.trace('Redirecting user to the location dashboard.');
        this.router.navigateByUrl(returnUrl || `/location/${token.claims.locId}`);
        return true;
      } else if (token.claims.role === Role.Technician) {
        this.logService.trace('Redirecting user to the technician dashboard.');
        this.router.navigateByUrl(returnUrl || `/search-asset`);
        return true;
      } else if (token.claims.role === Role.Kiosk) {
        this.logService.trace('Redirecting user to the kiosk dashboard.');
        this.router.navigateByUrl(returnUrl || `/location/${token.claims.locId}/kiosk`);
        return true;
      } else if (token.claims.role === Role.FirmwareEngineer) {
        this.logService.trace('Redirecting user to the OTA console.');
        this.router.navigateByUrl(returnUrl || `/ota-console`);
        return true;
      } else {
        this.logService.error('Invalid claims: ', token.claims);
        this.router.navigateByUrl(AppRoute.NotFound);
        return false;
      }
    } else {
      this.router.navigateByUrl(AppRoute.NotFound);
      return false;
    }
  }
}
