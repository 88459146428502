export class AppRoute {
  public static readonly Login = '/login';
  public static readonly ForgotPassword = '/forgot-password';
  public static readonly NotFound = '/not-found';

  public static readonly Locations = '/location-directory';
  public static readonly Users = '/user-directory';

  public static location(locId: string): string {
    return `/location/${locId}`;
  }

  public static asset(locId: string, assetId: string): string {
    return `${this.location(locId)}/asset/${assetId}`;
  }
}
