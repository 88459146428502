/* src/app/shared/aggregate/aggregate.component.scss */
.agg-counts {
  display: flex;
}
.agg-counts p {
  margin: 0.5rem;
}
.agg-count {
  flex: 1;
  display: inline-block;
  border-right: 0.0625rem solid #e9ecef;
  text-align: center;
}
.agg-count:last-child {
  border: none;
}
.filter.active {
  background-color: #e9f5f8;
}
.filter:hover {
  background-color: #f0f8fb;
  cursor: pointer;
}
.agg-number {
  font-size: 1.875rem;
  color: #dee2e6;
}
.agg-number.active-alert {
  font-weight: 700;
  color: #e84e25;
}
.agg-number.active-number {
  font-weight: 700;
  color: #456c9b;
}
/*# sourceMappingURL=aggregate.component.css.map */
