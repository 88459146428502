/* src/app/shared/paginator/paginator.component.scss */
:host {
  display: flex;
  justify-content: space-between;
  height: 2rem;
  margin: 0.25rem 0.625rem;
  color: #919ca6;
}
p {
  margin: 0;
  line-height: 2rem;
}
.item-display {
  display: inline-block;
  line-height: 2rem;
  vertical-align: bottom;
}
.page-control {
  display: inline-block;
  text-align: right;
}
.page-control > * {
  vertical-align: middle;
}
.page-control i {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  width: 0.875rem;
  text-align: center;
}
.page-control i:hover:not(.disabled) {
  cursor: pointer;
  color: #456c9b;
}
.page-control i.disabled {
  color: #dee2e6;
}
.page-control p {
  display: inline-block;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.page-control p.page-number {
  display: none;
}
.page-control input {
  display: inline-block;
  width: 2.5rem;
  margin-right: 0.25rem;
}
@media screen and (max-width: 345px) {
  .page-control input {
    display: none;
  }
  .page-control p.page-page {
    display: none;
  }
  .page-control p.page-number {
    display: inline-block;
  }
}
/*# sourceMappingURL=paginator.component.css.map */
