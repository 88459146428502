<div class="search-wrapper input-group">
  <span class="input-group-text"><i class="fas fa-search"></i></span>
  <input
    type="search"
    scAutoFocusOnLoad
    class="form-control"
    placeholder="Search MAC"
    [(ngModel)]="searchValue"
    (blur)="invalidSearch = false"
    (keyup.enter)="performSearch()"
    (paste)="onPaste()"
  />
</div>
<div class="error-result" *ngIf="invalidSearch">
  <div class="error-result-inner">
    <p>MAC address must be in the format XX-XX-XX-XX-XX-XX.</p>
  </div>
</div>
