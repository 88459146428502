import moment from 'moment-timezone';

import { Pipe, PipeTransform } from '@angular/core';

const tzFormat = /^(.*) (.*)$/g;

@Pipe({ name: 'tz' })
export class TimezonePipe implements PipeTransform {
  public transform(ianaTzCode: string): string {
    if (!moment.tz.zone(ianaTzCode)) {
      return ianaTzCode;
    }
    // currently, picking a date on Jan 1st and June 1st works for every timezone
    // for getting a date in standard and daylight savings time
    const year = new Date().getFullYear();
    const st = moment.tz(`${year}-01-01`, ianaTzCode);

    const stOffset = this.getOffsetName(st);
    return `(${stOffset}) ${ianaTzCode}`;
  }

  public getIanaTzCode(formattedTz: string): string {
    const matches = formattedTz.match(tzFormat);
    if (matches == null) {
      throw new Error(`Invalid formatted tz: ${formattedTz}`);
    }
    return matches.groups[1];
  }

  private getOffsetName(mmt: moment.Moment): string {
    const offsetName = mmt.format('z');
    const offset = mmt.format('Z');
    if (offset === offsetName) {
      return `GMT${offset}`;
    } else {
      return offsetName;
    }
  }
}
