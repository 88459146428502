export enum Hardware {
  ScentDirect = 'SCENT_DIRECT',
  ScentStream = 'SCENT_STREAM',
  ScentAirBreeze = 'SCENTAIR_BREEZE',
  ScentAirWhisperPro = 'SCENTAIR_WHISPER',
  ScentAirWhisperHome = 'SCENTAIR_WHISPER_HOME',
  ScentAirWhisperDistributor = 'SCENTAIR_WHISPER_DISTRIBUTOR',
  ScentAirWhisperIon = 'SCENTAIR_WHISPER_ION',
  ScentDirectBt = 'SCENT_DIRECT_BT',
  ScentAirWhisperMax = 'SCENTAIR_WHISPER_MAX',
  ScentDirectLl = 'SCENT_DIRECT_LL',
  ScentStreamLl = 'SCENT_STREAM_LL',
  ScentStreamBt = 'SCENT_STREAM_BT',
}

export interface HardwareProperties {
  name: string;
  htmlName: string;
  imagePath: string;
  hasBattery: boolean;
  hasPump: boolean;
  hasFan: boolean;
  hasScent: boolean;
  hasDuctSensing: boolean;
  usesTwoNozzles: boolean;
  isLinkLabsHardware: boolean;
}

const DIRECT_PROPS: HardwareProperties = {
  name: 'ScentAir Direct',
  htmlName: 'ScentAir Direct<sup>&trade;</sup>',
  imagePath: 'assets/images/direct.svg',
  hasBattery: false,
  hasPump: true,
  hasFan: true,
  hasScent: true,
  hasDuctSensing: false,
  usesTwoNozzles: false,
  isLinkLabsHardware: false,
};

const STREAM_PROPS: HardwareProperties = {
  name: 'ScentAir Stream',
  htmlName: 'ScentAir Stream<sup>&trade;</sup>',
  imagePath: 'assets/images/stream.svg',
  hasBattery: false,
  hasPump: true,
  hasFan: false,
  hasScent: true,
  hasDuctSensing: true,
  usesTwoNozzles: true,
  isLinkLabsHardware: false,
};

const BREEZE_PROPS: HardwareProperties = {
  name: 'ScentAir Breeze',
  htmlName: 'ScentAir Breeze<sup>&trade;</sup>',
  imagePath: 'assets/images/breeze.svg',
  hasBattery: false,
  hasPump: true,
  hasFan: true,
  hasScent: true,
  hasDuctSensing: false,
  usesTwoNozzles: false,
  isLinkLabsHardware: false,
};

const UNNAMED_WHISPER_PROPS: Omit<HardwareProperties, 'name' | 'htmlName' | 'imagePath'> = {
  hasBattery: false,
  hasPump: false,
  hasFan: true,
  hasScent: true,
  hasDuctSensing: false,
  usesTwoNozzles: false,
  isLinkLabsHardware: false,
};

// eslint-disable-next-line consistent-return
export function getHardwareProperties(value: Hardware): HardwareProperties {
  switch (value) {
    case Hardware.ScentDirect:
      return DIRECT_PROPS;
    case Hardware.ScentStream:
      return { ...STREAM_PROPS, hasBattery: true };
    case Hardware.ScentAirBreeze:
      return BREEZE_PROPS;
    case Hardware.ScentAirWhisperPro:
      return {
        ...UNNAMED_WHISPER_PROPS,
        name: 'ScentAir Whisper Pro',
        htmlName: 'ScentAir Whisper<sup>&trade;</sup> Pro',
        imagePath: 'assets/images/whisper-pro.svg',
      };
    case Hardware.ScentAirWhisperHome:
      return {
        ...UNNAMED_WHISPER_PROPS,
        name: 'ScentAir Whisper Home',
        htmlName: 'ScentAir Whisper<sup>&trade;</sup> Home',
        imagePath: 'assets/images/whisper-home.svg',
      };
    case Hardware.ScentAirWhisperDistributor:
      return {
        ...UNNAMED_WHISPER_PROPS,
        name: 'Signature Scent Diffuser',
        htmlName: 'Signature Scent Diffuser',
        imagePath: 'assets/images/whisper-distributor.svg',
      };
    case Hardware.ScentAirWhisperIon:
      return {
        ...UNNAMED_WHISPER_PROPS,
        name: 'ScentAir ION Boost',
        htmlName: 'ScentAir ION Boost<sup>&trade;</sup>',
        imagePath: 'assets/images/whisper-ion.svg',
        hasScent: false,
      };
    case Hardware.ScentDirectBt:
      return DIRECT_PROPS;
    case Hardware.ScentAirWhisperMax:
      return {
        ...UNNAMED_WHISPER_PROPS,
        name: 'ScentAir Whisper Max',
        htmlName: 'ScentAir Whisper<sup>&trade;</sup> Max',
        imagePath: 'assets/images/whisper-max.svg',
      };
    case Hardware.ScentDirectLl:
      return { ...DIRECT_PROPS, isLinkLabsHardware: true };
    case Hardware.ScentStreamLl:
      return { ...STREAM_PROPS, imagePath: 'assets/images/stream-lora.svg', isLinkLabsHardware: true };
    case Hardware.ScentStreamBt:
      return STREAM_PROPS;
  }
}
