import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { isMac } from '../util';

@Component({
  selector: 'sc-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  location: string;
  public searchValue: string;
  public invalidSearch = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.location = this.activatedRoute.snapshot.params.locId;
  }

  onPaste() {
    setTimeout(() => this.performSearch(), 0);
  }

  performSearch() {
    this.searchValue = this.searchValue.trim();

    if (!this.searchValue) {
      return;
    }

    if (!isMac(this.searchValue)) {
      this.invalidSearch = true;
      return;
    } else {
      this.invalidSearch = false;
    }

    const submitSearchValue = this.searchValue.toUpperCase();
    const url = `location/${this.location}/asset/${submitSearchValue}`;
    this.router.navigateByUrl(url);
    this.searchValue = '';
  }
}
